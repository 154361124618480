import { calculateTaxFromElements } from './payment.js'
import CountryStates from 'js/CountryStates'

window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.admin_orders.new')) {
    const form = document.querySelector('#payment-form');
    const countryEl = document.querySelector('select#order_shipping_country');
    const stateSelectEl = document.querySelector('select#order_shipping_state');
    const stateInputEl = document.querySelector('input#order_shipping_state');
    const postalCodeEl = document.querySelector('input#order_shipping_postal_code');
    const subtotalEl = document.querySelector('.order-summary .subtotal');
    const shippingEl = document.querySelector('#payment-form .order-summary .shipping');
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'});

    const calculateLineItemTotal = (el) => {
      const row = el.closest('tr');
      const priceText = row.querySelector('.price').textContent;
      const price = Number(priceText.replace(/[^0-9.-]+/g,""));
      const quantity = Number(row.querySelector('.quantity').value);
      row.querySelector('.lineTotal').textContent = formatter.format(price * quantity);
      calculateTotal(true);
    }

    const calculateTotal = (resetShipping = false) => {
      const rows = document.querySelectorAll('#payment-form tbody tr.order-line-item');
      let subtotal = 0;
      rows.forEach((row) => {
        const lineTotalText = row.querySelector('.lineTotal').textContent;
        const lineTotal = Number(lineTotalText.replace(/[^0-9.-]+/g,""));
        subtotal += lineTotal;
      });
      document.querySelector('#payment-form .order-summary .subtotal').textContent = formatter.format(subtotal);
      let shipping = Number(shippingEl.textContent.replace(/[^0-9.-]+/g,""));
      if (resetShipping) {
        shipping = 0;
        resetShippingOptions();
      }
      calculateTaxFromElements({ countryEl, stateSelectEl, stateInputEl, postalCodeEl, subtotalEl, shippingEl });
    }

    const changePrice = (el) => {
      const row = el.closest('tr');
      const selectedPrice = el.options[el.selectedIndex].dataset.price;
      row.querySelector('.price').textContent = formatter.format(selectedPrice);
    }

    const addRemoveFieldListener = (el) => {
      el.addEventListener('click', function(event) {
        this.closest('tr').remove()
        calculateTotal(true);
        return event.preventDefault();
      });
    }

    const addProductSelectListener = (el) => {
      el.addEventListener('change', function(event) {
        changePrice(el);
        calculateLineItemTotal(el);
      });
    }

    const addQuantityListener = (el) => {
      el.addEventListener('change', function(event) {
        calculateLineItemTotal(el);
      });
    }

    const getShippingRates = async () => {
      return await fetch("/orders/shipping_rates", {method:'POST', body: new FormData(form)})
        .then(response => response.json());
    }

    const resetShippingOptions = () => {
      if (!((stateSelectEl.value || stateInputEl.value) && countryEl.value && postalCodeEl.value)) { return };
      const shippingMethodContainer = form.querySelector('.shipping-method');
      document.querySelector('#payment-form .order-summary .shipping').textContent = '$0.00';
      shippingMethodContainer.textContent = 'Getting shipping options...'
      getShippingRates().then((rates) => {
        const list = rates.map((rate) => {
          let rateHTML = shippingMethodContainer.dataset['shippingMethodRadioButton']
          rateHTML = rateHTML.replace('replacetitle', rate['title'])
          rateHTML = rateHTML.replace('replaceamount', rate['priceV2']['amount'])
          rateHTML = rateHTML.replace('replacehandle', rate['handle'])
          return rateHTML;
        });
        shippingMethodContainer.innerHTML = list.join('');
        shippingMethodContainer.querySelectorAll('input').forEach((el) => {
          el.addEventListener('click', function(event) {
            const price = Number(el.closest('label').textContent.replace(/[^0-9.-]+/g,""));
            document.querySelector('#payment-form .order-summary .shipping').textContent = formatter.format(price);
            calculateTotal();
          });
        });
      });
    };

    form.querySelector('.add_fields').addEventListener('click', function(event) {
      event.preventDefault();
      const time = new Date().getTime();
      const regexp = new RegExp(this.dataset.id, 'g');
      form.querySelector('tbody').insertAdjacentHTML('beforeend', this.dataset['fields'].replace(regexp, time));
      const row = form.querySelector('tbody').lastChild
      addRemoveFieldListener(row.querySelector('.remove_fields'));
      addProductSelectListener(row.querySelector('.product-select'));
      addQuantityListener(row.querySelector('.quantity'));
      return event.preventDefault();
    });

    form.querySelectorAll('.remove_fields').forEach((el) => {
      addRemoveFieldListener((el));
    });


    form.querySelectorAll('tr td .product-select').forEach((el) => {
      addProductSelectListener(el);
    });

    form.querySelectorAll('tr td input.quantity').forEach((el) => {
      addQuantityListener(el);
    });


    [stateSelectEl, stateInputEl, countryEl, postalCodeEl].forEach((element) => {
      ['change', 'input'].forEach((event) => {
        element.addEventListener(event, () => {
          resetShippingOptions();
        });
      });
    });

    [stateSelectEl, countryEl, postalCodeEl].forEach((element) => {
      ['change', 'input'].forEach((event) => {
        element.addEventListener(event, () => {
          calculateTaxFromElements({ countryEl, stateSelectEl, stateInputEl, postalCodeEl, subtotalEl, shippingEl });
        });
      });
    });

    new CountryStates(countryEl, stateSelectEl, stateInputEl).connectCountryAndState();

    new CountryStates(
      document.querySelector('.invoice-billing-address select[name="country"]'),
      document.querySelector('.invoice-billing-address select[name="state"]'),
      document.querySelector('.invoice-billing-address input[name="state"]')
    ).connectCountryAndState();

    const billingAddress = document.querySelector('.billing-address');
    const newCard = document.querySelector('.new-card');
    const invoiceBillingAddress = document.querySelector('.invoice-billing-address');
    document.querySelector('#invoice_tab').addEventListener('click', (e) => {
      newCard.disabled = true;
      billingAddress.disabled = true;
      invoiceBillingAddress.disabled = false;
    });
    document.querySelector('#credit_card_tab').addEventListener('click', (e) => {
      newCard.disabled = false;
      billingAddress.disabled = false;
      invoiceBillingAddress.disabled = true;
    });
  }
});
