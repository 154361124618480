document.addEventListener('turbolinks:load', () => {
  const pageWithWarning = document.querySelector('[data-navigation-warning]');

  if (pageWithWarning) {
    const showWarning = (event) => {
      const confirmNavigation = confirm('Are you sure you want to leave this page? Any unsaved changes will be lost.');
      if (!confirmNavigation) {
        event.preventDefault();
      }
    }

    // Handle internal link clicks
    const handleClick = (event) => {
      const link = event.target.closest('a');
      if (link && !link.hasAttribute('data-no-warning')) {
        showWarning(event);
      }
    };

    // Clean up event listeners
    const cleanupListeners = () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('turbolinks:before-visit', cleanupListeners);
    };

    // Add event listeners
    document.addEventListener('click', handleClick);
    document.addEventListener('turbolinks:before-visit', cleanupListeners);
  }
});
