import Rails from "@rails/ujs";
import braintree from "braintree-web";
import CountryStates from "js/CountryStates";
import GooglePayButton from "js/GooglePayButton";
import PaypalButton from "js/PaypalButton";
import ApplePayButton from "js/ApplePayButton";
import { calculateTax } from "js/payment";

window.addEventListener("turbolinks:load", function () {
  const invoicePage = document.querySelector(".admin_orders.new");
  const paywallPage =
    document.querySelector(".orders.checkout") ||
    document.querySelector(".credit_cards.create") ||
    document.querySelector(".credit_cards.new") ||
    document.querySelector(".credits.index") ||
    document.querySelector(".credits.purchase") ||
    document.querySelector(".orders.purchase") ||
    document.querySelector(".partners.pay") ||
    document.querySelector(".partners.purchase") ||
    document.querySelector(".trainings.enroll") ||
    document.querySelector(".trainings.purchase") ||
    document.querySelector(".group_program.pay") ||
    document.querySelector(".group_program.purchase") ||
    invoicePage;
  if (!paywallPage) {
    return;
  }
  const form = document.querySelector("#payment-form");
  const submitButton = form.querySelector('input[type="submit"]') || document.querySelector(
    `input[type="submit"][form="${form.id}"]`
  );
  if (!submitButton) {
    console.error("No submit button found for payment form");
    return;
  }
  const authorization = form.querySelector("#client-token").value;

  const errorContainer = document.querySelector(".payment-errors");
  const loadingError = errorContainer?.dataset["loadingError"];
  const tokenError = errorContainer?.dataset["tokenError"];

  new CountryStates(
    document.querySelector('select[name="country"]'),
    document.querySelector('select[name="state"]'),
    document.querySelector('input[name="state"]')
  ).connectCountryAndState();

  const displayError = (message) => {
    if (!errorContainer) {
      console.warn(
        "No error container found to display payment form error message"
      );
      return;
    }
    errorContainer.textContent = message;
    errorContainer.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      Rails.enableElement(submitButton);
    }, 1000); // 1 second delay
  };

  if (!authorization) {
    console.error("No braintree authorization");
    return;
  } else {
    braintree.client.create(
      {
        authorization,
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error(clientErr);
          displayError(loadingError);
          return;
        }

        const cardNumberSelector = "#card-number";
        const cvvSelector = "#cvv";
        const expirationDateSelector = "#expiration-date";
        const selectorsPresent = [
          cardNumberSelector,
          cvvSelector,
          expirationDateSelector,
        ].every((selector) => document.querySelector(selector));

        if (!selectorsPresent) {
          console.error("Braintree hosted fields selectors not found");
          return;
        } else {
          braintree.hostedFields.create(
            {
              client: clientInstance,
              styles: {
                input: {
                  "font-size": "inherit",
                },
                "input.invalid": {
                  color: "red",
                },
                "input.valid": {
                  color: "green",
                },
              },
              fields: {
                number: {
                  container: cardNumberSelector,
                },
                cvv: {
                  container: cvvSelector,
                },
                expirationDate: {
                  container: expirationDateSelector,
                },
              },
            },
            function (hostedFieldsErr, hostedFieldsInstance) {
              if (hostedFieldsErr) {
                console.error(hostedFieldsErr);
                displayError(loadingError);
                return;
              }

              hostedFieldsInstance.on(
                "validityChange",
                function ({ fields, emittedBy }) {
                  const field = fields[emittedBy];
                  let validationEl = field.container.parentNode.querySelector(
                    ".validation-message"
                  );

                  if (!validationEl) {
                    validationEl = document.createElement("div");
                    validationEl.classList.add("validation-message", "hidden");
                    field.container.parentNode.append(validationEl);
                    validationEl.textContent =
                      field.container.parentNode.dataset["error"];
                  }

                  if (!(field.isValid || field.isPotentiallyValid)) {
                    validationEl.classList.remove("hidden");
                  } else {
                    validationEl.classList.add("hidden");
                  }
                }
              );

              const orderSummary = document.querySelector(".order-summary");
              if (
                orderSummary &&
                orderSummary.dataset["skipCalculation"] !== "true"
              ) {
                const updateTaxes = (event) => {
                  console.log("updateTaxes");
                  event && console.log({ target: event.target });
                  const country = form.querySelector(
                    "fieldset:not([disabled]) select#country"
                  ).value;
                  const state = form.querySelector(
                    "fieldset:not([disabled]) select#state"
                  ).value;
                  const postalCode = form.querySelector(
                    'fieldset:not([disabled]) input[name="postal_code"]'
                  ).value;
                  const subtotal =
                    form.sub_total.value ||
                    orderSummary.querySelector(".js-subtotal")?.value;
                  console.log({ country, state, postalCode, subtotal });
                  calculateTax({ country, state, postalCode, subtotal });
                };

                updateTaxes();
                [
                  'input[name="num_credits"]',
                  "select#country",
                  'input[name="postal_code"]',
                ].forEach((selector) => {
                  const field = document.querySelector(selector);
                  console.log({ field });
                  field && field.addEventListener("input", updateTaxes);
                });
                document
                  .querySelector("select#state")
                  .parentElement.addEventListener("input", updateTaxes);
              }

              form.addEventListener(
                "submit",
                function (event) {
                  if (
                    form.querySelector("#payment_nonce").length > 0 ||
                    newCard?.disabled
                  ) {
                    return;
                  }
                  event.preventDefault();

                  hostedFieldsInstance.tokenize(function (
                    tokenizeErr,
                    payload
                  ) {
                    if (tokenizeErr) {
                      console.error(tokenizeErr);
                      setTimeout(() => displayError(tokenError), 100);
                      return;
                    }

                    const nonce = payload.nonce;
                    form.querySelector("#payment_nonce").value = nonce;
                    form.submit();
                  });
                },
                false
              );
            }
          );
        }

        if (!invoicePage) {
          // Paypal
          const paypalButtonHandler = new PaypalButton(clientInstance, form);
          paypalButtonHandler.handleButton();

          // Apple Pay
          const applePayButtonHandler = new ApplePayButton(
            clientInstance,
            form
          );
          applePayButtonHandler.handleButton();

          // Google Pay
          const googlePayHandler = new GooglePayButton(clientInstance, form);
          googlePayHandler.handleButton();
        }
      }
    );

    // toggle new card / saved card
    const useSavedButton = document.querySelector(".use-saved");
    const enterNewButton = document.querySelector(".enter-new");
    const savedCard = document.querySelector(".saved-card");
    const newCard = document.querySelector(".new-card");
    const billingAddress = document.querySelector(".billing-address");

    if (enterNewButton && useSavedButton) {
      enterNewButton.addEventListener("click", function (event) {
        event.preventDefault();
        savedCard.classList.add("hidden");
        savedCard.disabled = true;
        newCard.classList.remove("hidden");
        newCard.disabled = false;
        billingAddress.classList.remove("hidden");
        billingAddress.disabled = false;
      });
      useSavedButton.addEventListener("click", function (event) {
        event.preventDefault();
        newCard.classList.add("hidden");
        newCard.disabled = true;
        billingAddress.classList.add("hidden");
        billingAddress.disalbed = true;
        savedCard.classList.remove("hidden");
        savedCard.disabled = false;
      });
    }

    // Promo Code
    const submitPromoCode = async (form) => {
      return await fetch(form.action, {
        method: "PATCH",
        body: new FormData(form),
      }).then((response) => response.json());
    };
    const promoForm = document.querySelector("#promo_code");
    const promoError = document.querySelector(".promo-code-error");

    if (!promoForm) {
      return;
    }
    promoForm.addEventListener("submit", function (event) {
      event.preventDefault();
      submitPromoCode(promoForm).then((response) => {
        if (response.success) {
          const redirectUrl = response.redirect_url;
          const parser = new URL(window.location);
          parser.searchParams.set("promo_code_applied", true);
          window.location = redirectUrl || parser.href;
        } else if (response.error) {
          promoError.textContent = response.error;
        } else {
          promoError.textContent = promoError.dataset["error"];
        }
        promoForm.querySelector('input[type="submit"]').disabled = false;
      });
    });
  }
});
