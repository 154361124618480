import customSelect from 'custom-select';

const countries = {"US":[["State",null],["Alabama","AL"],["Alaska","AK"],["Arizona","AZ"],["Arkansas","AR"],["California","CA"],["Colorado","CO"],["Connecticut","CT"],["Delaware","DE"],["District of Columbia","DC"],["Florida","FL"],["Georgia","GA"],["Hawaii","HI"],["Idaho","ID"],["Illinois","IL"],["Indiana","IN"],["Iowa","IA"],["Kansas","KS"],["Kentucky","KY"],["Louisiana","LA"],["Maine","ME"],["Maryland","MD"],["Massachusetts","MA"],["Michigan","MI"],["Minnesota","MN"],["Mississippi","MS"],["Missouri","MO"],["Montana","MT"],["Nebraska","NE"],["Nevada","NV"],["New Hampshire","NH"],["New Jersey","NJ"],["New Mexico","NM"],["New York","NY"],["North Carolina","NC"],["North Dakota","ND"],["Ohio","OH"],["Oklahoma","OK"],["Oregon","OR"],["Pennsylvania","PA"],["Puerto Rico","PR"],["Rhode Island","RI"],["South Carolina","SC"],["South Dakota","SD"],["Tennessee","TN"],["Texas","TX"],["Utah","UT"],["Vermont","VT"],["Virginia","VA"],["Washington","WA"],["West Virginia","WV"],["Wisconsin","WI"],["Wyoming","WY"]],"CA":[["Prov.",null],["Alberta","AB"],["British Columbia","BC"],["Manitoba","MB"],["New Brunswick","NB"],["Newfoundland and Labrador","NL"],["Nova Scotia","NS"],["Nunavut","NU"],["Northwest Territories","NT"],["Ontario","ON"],["Prince Edward Island","PE"],["Quebec","QC"],["Saskatchewan","SK"],["Yukon","YT"]]}

class CountryStates {

  constructor(countrySelect, stateSelect, textElem) {
    this.countrySelect = countrySelect;
    this.stateSelect = stateSelect;
    this.textElem = textElem;
  }

  selectOptionsFor(country, selectedState) {
    if (countries[country] !== undefined) {
      return (Array.from(countries[country]).map((c) => `<option value='${c[1] || ''}' ${(c[1] == selectedState) ? 'selected' : ''}>${c[0]}</option>`)).join("\n");
    }
  }

  setSelectOptions() {
    const country = this.countrySelect.value
    const selectedState = this.stateSelect.value;
    const options = this.selectOptionsFor(country, selectedState);
    if (this.customStateSelect !== undefined) {
      this.customStateSelect.destroy();
      this.customStateSelect = undefined;
    }
    if ((options === undefined) && (this.textElem !== undefined)) {
      return this.showTextElem();
    } else {
      this.stateSelect.innerHTML = options;
      return this.showSelectElem();
    }
  }

  showSelectElem() {
    this.textElem.setAttribute('disabled', true);
    this.textElem.closest('.js-country-state-select-container').classList.add('hidden');
    this.stateSelect.removeAttribute('disabled');
    this.customStateSelect = customSelect(this.stateSelect)[0];
    window.initCustomSelect(this.customStateSelect);
    this.stateSelect.closest('.js-country-state-select-container').classList.remove('hidden');
  }

  showTextElem() {
    this.stateSelect.setAttribute('disabled', true);
    this.stateSelect.closest('.js-country-state-select-container').classList.add('hidden');
    this.textElem.removeAttribute('disabled');
    this.textElem.closest('.js-country-state-select-container').classList.remove('hidden');
  }

  connectCountryAndState () {
    if (!this.countrySelect || !this.stateSelect || !this.textElem) { return; }
    this.countrySelect.addEventListener('change', (e) => {
      this.setSelectOptions()
    });
    this.setSelectOptions()
  }
}

export default CountryStates;
